import 'babel-polyfill';
import Vue from 'vue'
import Vuetify, {
  VApp // required
} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import Nprogress from 'nprogress'
import VueBreadcrumbs from 'vue2-breadcrumbs'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import VeeValidate from 'vee-validate';
import enValidationMessages from 'vee-validate/dist/locale/en';
import ptValidationMessages from 'vee-validate/dist/locale/pt_PT';
import itValidationMessages from 'vee-validate/dist/locale/it';
import './features/creativeBuilder/externalTools/prototypes'
import { disableAll } from './features/creativeBuilder/directives/disableAll';

// global components
import GlobalComponents from './globalComponents'

// app.vue
import App from './App'

//vueTelInputVutify
import VueTelInputVuetify from './plugins/VueTelInput/lib/plugin';
Vue.use(VueTelInputVuetify)

// material icons
import MaterialIcon from './components/MaterialIcon/MaterialIcon'

// router
import router from './router'

// store
import { store } from './store/store';

import { i18n } from './lang'

// include all css files
import './platformCss'
import VDateRange from 'vuetify-daterange-picker';
import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css';

// Bugsnag
import { BugsnagConfig } from './bugsnagConfig';

BugsnagConfig.configure();

// disable loading spinner on top-right
Nprogress.configure({ showSpinner: false })

// Used for copy on click
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

Vue.use(VDateRange);
Vue.use(GlobalComponents);

import { icons } from './assets/icons/icons'

import { GoogleSignin } from '@deltax/google-signin';

function missingMaterialIcons(ids) {
  const icons = {}
  for (const id of ids) {
    for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
      const name = `${id}_${suffix}`
      icons[name] = {
        component: MaterialIcon,
        props: {
          name
        }
      }
    }
  }
  return icons
}

//custom vue directives
Vue.directive('disable-all', disableAll)

// plugins
Vue.use(Vuetify, {
  components: {
    VApp
  },
  directives: {
    Ripple
  },
  options: {
    customProperties: true
  },
  theme: store.getters.selectedTheme.theme,
  icons: {
    ...missingMaterialIcons(icons)
  }
});

Vue.use(VueBreadcrumbs)
Vue.use(Notifications, { velocity })

Vue.use(VeeValidate, {
  i18nRootKey: 'validations', // customize the root path for validation messages.
  i18n,
  dictionary: {
    en: enValidationMessages,
    'pt_PT': ptValidationMessages,
    it: itValidationMessages
  }
});

import VMdDateRangePicker from 'v-md-date-range-picker';
Vue.use(VMdDateRangePicker);

import { GlobalRouterGuard } from './globalRouterGuard'

GlobalRouterGuard.configure(Nprogress, i18n, store);

Vue.use(GoogleSignin);

/* eslint-disable no-new */
new Vue({
  store,
  i18n,
  router,
  render: h => h(App),
  components: { App }
}).$mount('#app')
