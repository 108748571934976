export const disableAll = {
  componentUpdated(el, binding) {
    if (!binding.value) return;

    const tags = ['input', 'button', 'textarea', 'select'];
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });

    const vuetifyComponents = el.querySelectorAll('.v-select, .v-text-field, .v-input, .v-switch');
    vuetifyComponents.forEach(component => {
      component.classList.add('v-input--disabled', 'v-input--is-disabled');
      const input = component.querySelector('input');
      if (input) {
        input.disabled = true;
      }
      const menu = component.querySelector('.v-menu');
      if (menu) {
        menu.classList.add('v-menu--disabled');
      }
    });
  }
};